import React from "react"
import { RouteComponentProps } from "react-router"
import {
  errors,
  warnings,
  ErrorCode,
  WarningCode,
  ErrorInfo,
} from "../models/ErrorMessages"
import cx from "classnames"

const ErrorsPage: React.FC<RouteComponentProps> = () => {
  const errorsAndWarnings: {
    [K in ErrorCode | WarningCode]: ErrorInfo
  } = Object.assign({}, errors, warnings)
  const errorAndWarningNames = Object.keys(errorsAndWarnings) as Array<
    ErrorCode | WarningCode
  >

  return (
    <div>
      <div className="d-flex flex-wrap border-top pb-3">
        {errorAndWarningNames.map(name => (
          <div className="p-2" key={name}>
            <a
              className={cx("btn", "btn-sm", "text-nowrap", {
                "btn-warning": Object.keys(warnings).includes(name),
                "btn-danger": !Object.keys(warnings).includes(name),
              })}
              href={"#" + name}
            >
              {name}
            </a>
          </div>
        ))}
      </div>
      <div>
        <h2>Errors</h2>
        {Object.keys(errors).map(name => (
          <ErrorCard key={name} name={name} type="error" />
        ))}
        <h2>Warnings</h2>
        {Object.keys(warnings).map(name => (
          <ErrorCard key={name} name={name} type="warning" />
        ))}
      </div>
    </div>
  )
}

interface CardProps {
  name: string
  type: "warning" | "error"
}
const ErrorCard: React.FC<CardProps> = ({ name, type }) => {
  const error =
    type === "error" ? errors[name as ErrorCode] : warnings[name as WarningCode]

  return (
    <div id={name} className="card card-body mt-2 mb-2">
      <h4 className="card-title">{name}</h4>
      <p className="card-subtitle mb-2 text-muted">{error.description}</p>

      <div className={`alert alert-${type === "error" ? "danger" : "warning"}`}>
        <strong id={`${type}-message`}>
          {type === "error" ? "Error" : "Warning"}:{" "}
        </strong>
        {error.message}
      </div>
    </div>
  )
}

export default ErrorsPage
