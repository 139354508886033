import React, { useState, useEffect } from "react"
import useApiClient from "../hooks/useApiClient"
import Project from "../models/Project"
import { RouteComponentProps } from "react-router"
import AdminProjectTable from "../components/AdminProjectTable"
import Spinner from "../components/Spinner"

export interface Props extends RouteComponentProps {
  idToken: IdToken
}

const AdminProjectsListPage: React.FC<Props> = ({ idToken }) => {
  const api = useApiClient<{ projects: Project[] }>()
  const [projects, setProjects] = useState<any[]>()

  // not sure if this is the right way to do it?
  useEffect(() => {
    // TODO: error handling
    api
      .get("/api/projects", {
        headers: {
          Authorization: `Bearer ${idToken.__raw}`,
        },
      })
      .then(res => {
        setProjects(res.data.projects)
      })
  }, [idToken, api])

  return projects ? <AdminProjectTable projects={projects} /> : <Spinner />
}

export default AdminProjectsListPage
