import React, { useState, MouseEvent, useRef, useEffect } from "react"
import Modal from "react-modal"
import Project from "../models/Project"
import copyToClipboard from "copy-to-clipboard"
import cx from "classnames"

Modal.setAppElement("#root")

interface Props {
  project: Project
  url: string
  onClose(): void
}

const ProjectLinkModal: React.FC<Props> = ({ project, url, onClose }) => {
  const fullUrl = window.location.origin + url
  const [isOpen] = useState(true)
  const [isCopied, setIsCopied] = useState(false)
  const copiedTimeoutRef = useRef<number>()

  useEffect(() => {
    return () => {
      if (copiedTimeoutRef.current) clearTimeout(copiedTimeoutRef.current)
    }
  }, [])

  const handleCopyClick = (e: MouseEvent) => {
    e.preventDefault()
    copyToClipboard(fullUrl)
    setIsCopied(true)

    copiedTimeoutRef.current = window.setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      bodyOpenClassName="modal-open"
      className="modal-dialog modal-lg"
      onRequestClose={onClose}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{project.name}</h4>
          <button
            type="button"
            className="close"
            onClick={onClose}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              readOnly
              value={fullUrl}
            />
            <div className="input-group-append">
              <button
                className={cx("btn", "btn-block", "mb-4", {
                  "btn-secondary": !isCopied,
                  "btn-success": isCopied,
                })}
                onClick={handleCopyClick}
              >
                {isCopied ? "Copied" : "Copy"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ProjectLinkModal
