import React, { useState } from "react"
import Project from "../models/Project"
import useAuth0 from "../hooks/useAuth0"
import useApiClient from "../hooks/useApiClient"
import ProjectLinkModal from "./ProjectLinkModal"
import { useHistory } from "react-router"

export interface Props {
  projects: any[]
}

const searchFields = ["projectNumber", "name", "customerName"] as const

function filterProjects(query: string, projects: Project[]) {
  if (!query) return projects

  return projects.filter(p =>
    searchFields.some(f => p[f]?.toLowerCase().includes(query.toLowerCase()))
  )
}

const AdminProjectTable: React.FC<Props> = ({ projects: allProjects }) => {
  const { getIdTokenClaims } = useAuth0()

  const history = useHistory()
  const [query, setQuery] = useState<string>("")
  const [currentProject, setCurrentProject] = useState<{
    project: Project
    url: string
  }>()
  const api = useApiClient<{ url: string }>()

  const projects = filterProjects(query, allProjects)

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value)
  }

  const getProjectLink = (project: Project) => {
    const threeMonths = 3 * 30 * 24 * 60 * 60 * 1000
    const expires = Date.now() + threeMonths

    return getIdTokenClaims!().then(idToken => {
      return api.get("/api/project-links", {
        params: {
          id: project.projectNumber,
          expires: expires,
        },
        headers: {
          Authorization: `Bearer ${idToken.__raw}`,
        },
      })
    })
  }

  const handleCreateClick = (proj: Project) => (e: React.MouseEvent) => {
    e.preventDefault()
    getProjectLink(proj).then(res => {
      setCurrentProject({ project: proj, url: res.data.url })
    })
  }

  const handleGoToProjectClick = (proj: Project) => (e: React.MouseEvent) => {
    e.preventDefault()
    getProjectLink(proj).then(res => {
      history.push(res.data.url)
    })
  }
  const handleModalClose = () => {
    setCurrentProject(undefined)
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col-sm-6">
          <input
            type="text"
            value={query}
            onChange={handleFilterChange}
            className="form-control"
            placeholder="Type to Filter Projects..."
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>Project</th>
                <th>Customer</th>
                <th>Project ID</th>
                <th>Meters</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {projects.map(project => (
                <tr key={project.projectNumber}>
                  <td>
                    <a href="#" onClick={handleGoToProjectClick(project)}>
                      {project.name}
                    </a>
                  </td>
                  <td>{project.customerName}</td>
                  <td>{project.projectNumber}</td>
                  <td>{project.meterCount}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary text-nowrap"
                      onClick={handleCreateClick(project)}
                    >
                      Create Link
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {currentProject && (
        <ProjectLinkModal
          project={currentProject.project}
          url={currentProject.url}
          onClose={handleModalClose}
        />
      )}
    </>
  )
}

export default AdminProjectTable
