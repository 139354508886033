export interface ErrorInfo {
  description: string
  message: string
  howToReproduce: string
}

const warnings: Record<string, ErrorInfo> = {
  wrong_voltage_phase: {
    description:
      "The amperage on at least one of the lines is at least 0.2 A, but the voltage is less than 100 V.",
    message:
      "Please provide line voltage to the meter terminal corresponding with this CT, ensuring that the phase of the line voltage matches the phase of the wire the CT is measuring. If not possible, please reach out to your Allumia contact.",
    howToReproduce: "Unknown",
  },
  power_factor_negative: {
    description: "The power factor on one or more voltage lines is negative.",
    message:
      "Please switch direction of corresponding Circuit Transformer(s), or switch the wire order for corresponding Circuit Transformer(s).",
    howToReproduce:
      "If this isn't already happening when you try to measure the current from a wire, and the power factor is positive, switch the direction that the wire is going through the CT and it will be negative.",
  },
  power_factor_low: {
    description:
      "The power factor on one or more voltage lines is less than 0.7.",
    message:
      "Power factor lower than expected. Please ensure that circuit transformer phase matches corresponding voltage phase. If you are using one circuit transformer to grab multiple wires, please ensure that both wires are going in the same direction, and that both wires are of the same phase.",
    howToReproduce:
      "This might happen naturally, but if it doesn't, get two wires that both individually have positive readings, and take the one that has less amperage and put that one through the CT backward; put the stronger one the correct direction.",
  },
}

// Error message text
// parses our api response codes to user facing error messages
const errors: Record<string, ErrorInfo> = {
  server_error: {
    description:
      "Unhandled exception on our backend (catch-all if we don't handle the error).",
    message:
      "Unknown Internal Server error. Please try again, and contact Allumia point person for assistance if the problem persists.",
    howToReproduce:
      'Add a line in the "/refresh/:id" endpoint that throws an error (for instance, on line 143, add `console.log(undefined.length)`).',
  },
  project_not_found: {
    description:
      'There is no project ID for the meter (not even the generic "Unassigned" project). This probably shouldn\'t happen.',
    message:
      "Project not found or invalid. Contact Allumia point person for assistance.",
    howToReproduce:
      "In a test/local database, make a new meter without a project ID, or delete a meter's project (make sure that the deleting doesn't cascade).",
  },
  meter_not_found: {
    description:
      'There is no meter with that ID in the Product. This will happen when you manually type in the meter number incorrectly on the "Metering" tab, the meter is old enough that it has not been moved over to the product, or the meter is not a particle at all.',
    message:
      "Meter ID not found. Double check the label on device, and try again.",
    howToReproduce:
      "Type in a meter ID on the /admin/meters page that doesn't exist (for instance, 123456789). This error will also show up for a particle that isn't on the Product (any Electron, Pi, or EKM).",
  },
  meter_offline: {
    description:
      "The particle has not connected to the Particle cloud in a long time (on the order of days).",
    message:
      "Particle device offline. Please check that the antenna is connected to the computer board, and that the computer board is receiving power. If device LED is off, check power supply. If device LED is blinking green, and doesn't change to cyan, check antenna. Contact Allumia point person is problem persists.",
    howToReproduce:
      "Find a device on the Particle Product that hasn't connected in the last few days, and try to read data from it on the admin/meters page, or on its project page.",
  },
  meter_read_failed: {
    description:
      "The particle was not able to read data off the meter registers.",
    message:
      "Please ensure the data wires from the AcuRev meter are well-connected to the computer board. If wires are securely connected, check meter settings using the Set and Scroll buttons on the meter. Address should read 200, Baud Rate should be 9600, and CT ratio should match the CT amperage (usually 50 or 100).",
    howToReproduce:
      "Unplug the particle's data wires from its meter and try to read from it (not 100% sure about this one).",
  },
  particle_unowned_error: {
    description:
      "The particle was not claimed by the ablock@allumia.com Particle account, so we are not able to call functions on it.",
    message:
      "Particle device is not correctly registered. Please contact Allumia point person for assistance. If you do not have a point of contact at Allumia, please call our office at 425-298-7834. We are usually in the office from 9 am to 5 pm, PST, Monday to Friday.",
    howToReproduce:
      "Try reading data from an unclaimed particle (probably the easiest way is to flash a new device with the firmware and test it before claiming it).",
  },
  particle_firmware_error: {
    description:
      "The particle is on the product, but doesn't have recent enough software.",
    message:
      "Particle device firmware out of date. Please contact Allumia point person for assistance.",
    howToReproduce:
      "Try reading data from a meter that is on the product but only has v2 firmware. (You should be able to find one from this page: https://console.particle.io/boron-meter-controller-v100/devices.)",
  },
  particle_listen_timeout: {
    description:
      "The particle did not send the data from the reportNow function within the timeout period (currently 15s).",
    message:
      "Connection to device timed out. Please try again. If problem persists, contact Allumia point person for assistance.",
    howToReproduce:
      'This is something we\'re unlikely to see unless a device has very poor but occasional connection. The easiest way to test it is to change the timeout values in the "/refresh/:id" endpoint so that the listen timeout is much shorter than the call timeout. Then unplug the meter right before trying to read data from it.',
  },
  particle_call_timeout: {
    description:
      "The particle did not respond to calling the reportNow function within the timeout period (currently 15s).",
    message:
      "Unable to connect to device. Please try again. If problem persists, contact Allumia point person for assistance.",
    howToReproduce:
      "Unplug the meter (a.k.a. cut power to the particle) or remove the antenna from the particle just before trying to read data from it.",
  },
}
// Warning message text

export type ErrorCode = keyof typeof errors
export type WarningCode = keyof typeof warnings

export { errors, warnings }
