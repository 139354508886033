import React from "react"
import { Link } from "react-router-dom"

const HomePage: React.FC<{}> = () => {
  return (
    <>
      <div className="row">
        <div className="col">
          <h1>Allumia Meter Testing Portal</h1>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col">
            <p>
              Welcome to the Meter Testing Portal! To gain authorization, ask
              your contact at Allumia for a link.
            </p>
            <p>
              Allumia employee? <Link to="/admin">Click here.</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage
