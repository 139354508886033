import React, { useState, useEffect } from "react"

import {
  useHistory,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
} from "react-router"
import Auth0Provider from "../components/Auth0Provider"
import useAuth0, { Auth0State } from "../hooks/useAuth0"
import Spinner from "../components/Spinner"
import AdminProjectsListPage from "./AdminProjectsListPage"
import ErrorsPage from "./ErrorsPage"
import MeterForm from "../components/MeterForm"
import { NavLink } from "react-router-dom"

// TODO: clean up this mess

const AdminPage: React.FC<RouteComponentProps> = () => {
  // const history = useHistory()

  const handleRedirect = (state: Auth0State) => {
    console.log("handleRedirect", state)
    // TODO: redirect based on path
    // history.push("/admin")
  }

  return (
    <Auth0Provider
      onRedirectCallback={handleRedirect}
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirect_uri={window.location.origin + "/admin"}
    >
      <AdminPageInner />
    </Auth0Provider>
  )
}

const AdminPageInner: React.FC<{}> = () => {
  const [idToken, setIdToken] = useState<IdToken>()
  const history = useHistory()

  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    // user,
    // getTokenSilently,
    getIdTokenClaims,
  } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated) return

    // TODO: error handling
    getIdTokenClaims!().then(token => {
      setIdToken(token)
    })
  }, [isAuthenticated, getIdTokenClaims])

  const handleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault()
    loginWithRedirect!({})
  }

  const handleLogoutClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    logout!()
    history.push("/")
  }

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      loginWithRedirect!({})
    }
  }, [loading, isAuthenticated, loginWithRedirect])

  if (loading) return <Spinner />

  if (isAuthenticated && idToken) {
    return (
      <>
        <div className="row">
          <div className="col-md">
            <h1>Allumia Meter Admin</h1>
          </div>
          <div className="col-md text-md-right">
            <button className="btn btn-secondary" onClick={handleLogoutClick}>
              Log Out
            </button>
          </div>
        </div>
        <div className="row mt-2 mb-4">
          <div className="col">
            <ul className="nav nav-pills">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/admin/projects"
                >
                  Project List
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/admin/meters"
                >
                  Meter Lookup
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/admin/errors"
                >
                  Error List
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <Switch>
          <Route
            path="/admin/projects"
            exact
            render={props => (
              <AdminProjectsListPage {...props} idToken={idToken} />
            )}
          />
          <Route
            path="/admin/meters"
            exact
            render={props => <MeterForm {...props} idToken={idToken} />}
          />
          <Route
            path="/admin/errors"
            exact
            render={props => <ErrorsPage {...props} />}
          />
          <Redirect to="/admin/projects" />
        </Switch>
      </>
    )
  } else {
    return (
      <div>
        <Spinner />
        <p>Redirecting... </p>

        <p>
          <a onClick={handleLoginClick} href="#">
            Click here
          </a>{" "}
          if you are not redirected automatically.
        </p>
      </div>
    )
  }
}

export default AdminPage
