import React, { useState } from "react"
import MeterData from "../models/MeterData"

import useApiClient from "../hooks/useApiClient"
import { AxiosError, Cancel } from "axios"
import MeterDataTable from "./MeterDataTable"
import Spinner from "./Spinner"
import Meter from "../models/Meter"
import ContractorAuth from "../models/ContractorAuth"
import { errors, ErrorCode } from "../models/ErrorMessages"

type RequestStatus = "idle" | "busy" | "success" | "error"

export type Props = {
  meters?: Meter[]
  contractorAuth?: ContractorAuth
  idToken?: IdToken
} & ({ contractorAuth: ContractorAuth } | { idToken: IdToken })

const MeterForm: React.FC<Props> = ({ meters, contractorAuth, idToken }) => {
  const [meterId, setMeterId] = useState(
    // if an array of meters was passed and the array has at least one element,
    // use the id of the first meter; otherwise, start as blank
    meters && meters.length ? meters[0].id : ""
  )
  const [meterData, setMeterData] = useState<MeterData | undefined>(undefined)
  const [requestStatus, setRequestStatus] = useState<RequestStatus>("idle")
  const [errorCode, setErrorCode] = useState<ErrorCode | undefined>()
  const api = useApiClient<MeterData>()

  const handleMeterIdChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setMeterId(e.currentTarget.value)
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    // TODO: gross
    setMeterData(undefined)
    setRequestStatus("busy")
    setErrorCode(undefined)
    const refreshPromise = contractorAuth
      ? api.get(`/api/refresh/${meterId}`, {
          params: {
            expires: contractorAuth.expires,
            hmac: contractorAuth.hmac,
          },
        })
      : api.get(`/api/refresh/${meterId}`, {
          headers: {
            Authorization: `Bearer ${idToken!.__raw}`,
          },
        })
    refreshPromise
      .then(response => {
        setMeterData(response.data)
        setRequestStatus("success")
      })
      .catch((ex: AxiosError<{ code: ErrorCode }> | Cancel) => {
        if (ex.message === "cancelled") {
          // setErrorMessage(ex.message)
        } else if ("response" in ex && ex.response) {
          console.log(ex.response.data)
          // TODO: if there is someother error caught here, won't work w/ ErrorCode type
          // will probably get a weird undefined error state
          setErrorCode(ex.response.data.code)
        }

        setRequestStatus("error")
      })
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    api.cancel()
  }

  return (
    <form id="meter-form" method="post" onSubmit={handleSubmit}>
      <h1 className="h3 mb-2 text-center">Allumia Meter Test</h1>
      <h2 className="h5 mb-4 text-center">
        {meters && meters.length ? "Select a " : "Enter a "} Meter ID
      </h2>

      <div className="input-group mb-3">
        {meters ? (
          <select
            className="form-control form-control-lg"
            onChange={handleMeterIdChange}
          >
            {meters.map(meter => (
              <option value={meter.id} key={meter.id}>
                {meter.id}
              </option>
            ))}
          </select>
        ) : (
          <input
            placeholder="Meter ID"
            className="form-control form-control-lg"
            autoFocus
            type="text"
            value={meterId}
            onChange={handleMeterIdChange}
            required
            disabled={requestStatus === "busy"}
          />
        )}

        <div className="input-group-append">
          {requestStatus === "busy" ? (
            <button
              className="btn btn-lg btn-danger btn-block mb-4"
              onClick={handleCancel}
            >
              Cancel
            </button>
          ) : (
            <button
              className="btn btn-lg btn-primary btn-block mb-4"
              type="submit"
              name="submit"
            >
              Check Meter
            </button>
          )}
        </div>
      </div>

      {requestStatus === "error" && (
        <div id="error-alert" className="alert alert-danger">
          <strong id="error-message">Error: </strong>
          {errors[errorCode || "server_error"].message ||
            "Unknown Error Occurred"}
        </div>
      )}

      {requestStatus === "busy" && <Spinner />}

      {!!meterData && <MeterDataTable meterData={meterData} />}
    </form>
  )
}

export default MeterForm
