import React, { useContext } from "react"
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"

export interface Auth0State
  extends Pick<
    Auth0Client,
    | "handleRedirectCallback"
    | "loginWithPopup"
    | "getIdTokenClaims"
    | "loginWithRedirect"
    | "getTokenSilently"
    | "getTokenWithPopup"
    | "logout"
  > {
  isAuthenticated: boolean
  user: any
  loading: boolean
  popupOpen: boolean
}

export const Auth0Context = React.createContext<Partial<Auth0State>>(undefined!)

const useAuth0 = () => useContext(Auth0Context)

export default useAuth0
