import { useState, useEffect } from "react"

import APIClient from "../services/APIClient"
import { AxiosRequestConfig } from "axios"

export default function useApiClient<T = any>(config?: AxiosRequestConfig) {
  const [api] = useState<APIClient<T>>(() => {
    return new APIClient<T>(config)
  })

  useEffect(() => {
    return () => {
      api.cancel()
    }
  }, [api])

  return api
}
