import React from "react"
import MeterData from "../models/MeterData"
import cx from "classnames"
import { warnings } from "../models/ErrorMessages"

interface MeterDataTableProps {
  meterData: MeterData
}

const NULL_PLACEHOLDER = (
  <span>
    <em>no data</em>
  </span>
)

const MeterDataTable: React.FC<MeterDataTableProps> = ({ meterData }) => {
  const checkWrongVoltagePhase = meterData.lines.some(
    ln =>
      ln.amps != null &&
      ln.rmsVolts != null &&
      ln.amps > 0.2 &&
      ln.rmsVolts < 100
  )
  const checkPowerFactorNeg = meterData.lines.some(
    ln => ln.powerFactor != null && ln.powerFactor < 0
  )
  const checkPowerFactorLow = meterData.lines.some(
    ln => ln.powerFactor != null && ln.powerFactor < 0.7
  )

  return (
    <div>
      {checkWrongVoltagePhase ? (
        <div className="alert alert-warning">
          <strong>Warning: </strong>
          {warnings["wrong_voltage_phase"].message}
        </div>
      ) : checkPowerFactorNeg ? (
        <div className="alert alert-warning">
          <strong>Warning: </strong>
          {warnings["power_factor_negative"].message}
        </div>
      ) : (
        checkPowerFactorLow && (
          <div className="alert alert-warning">
            <strong>Warning: </strong>
            {warnings["power_factor_low"].message}
          </div>
        )
      )}

      <h5 className="text-center">Channel-Level Measurements</h5>

      <table className="table table-sm" id="meter-table">
        <thead>
          <tr>
            <th>Ln</th>
            <th>Volts</th>
            <th>Amps</th>
            <th>Watts</th>
            <th>Power Factor</th>
          </tr>
        </thead>
        <tbody>
          {meterData.lines.map((line, i) => (
            <tr key={i}>
              <th>{i + 1}</th>
              <td
                className={cx({
                  "table-warning":
                    line.rmsVolts == null ||
                    line.amps == null ||
                    line.rmsVolts < 0 ||
                    (line.rmsVolts < 100 && line.amps > 0.2),
                })}
              >
                {line.rmsVolts?.toFixed(2) ?? NULL_PLACEHOLDER}
              </td>
              <td
                className={cx({
                  "table-warning": line.amps == null || line.amps < 0,
                })}
              >
                {line.amps?.toFixed(4) ?? NULL_PLACEHOLDER}
              </td>
              <td
                className={cx({
                  "table-warning": line.rmsWatts == null || line.rmsWatts < 0,
                })}
              >
                {line.rmsWatts?.toFixed(2) ?? NULL_PLACEHOLDER}
              </td>
              <td
                className={cx({
                  "table-warning":
                    line.powerFactor == null || line.powerFactor < 0.7,
                })}
              >
                {line.powerFactor?.toFixed(4) ?? NULL_PLACEHOLDER}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h5 className="text-center">Meter-Level Measurements</h5>

      <table className="table table-sm">
        <thead>
          <tr>
            <th>Volts A-B</th>
            <th>Volts B-C</th>
            <th>Volts C-A</th>
            <th>Total Power Factor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{meterData.voltsAb?.toFixed(2) ?? NULL_PLACEHOLDER}</td>
            <td>{meterData.voltsBc?.toFixed(2) ?? NULL_PLACEHOLDER}</td>
            <td>{meterData.voltsCa?.toFixed(2) ?? NULL_PLACEHOLDER}</td>
            <td>
              {meterData.powerFactorTotal?.toFixed(4) ?? NULL_PLACEHOLDER}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MeterDataTable
