import Axios, { AxiosRequestConfig, AxiosPromise, Method } from "axios"

// Laboriously wrap axios so we can override request and wrap it with things
export default class AxiosProxy<T> {
  axios = Axios.create()

  request(config: AxiosRequestConfig) {
    return this.axios.request(config) as AxiosPromise<T>
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.buildBodylessRequest("get", url, config)
  }
  delete(url: string, config?: AxiosRequestConfig) {
    return this.buildBodylessRequest("delete", url, config)
  }
  head(url: string, config?: AxiosRequestConfig) {
    return this.buildBodylessRequest("head", url, config)
  }
  post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.buildBodyRequest("post", url, data, config)
  }
  put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.buildBodyRequest("put", url, data, config)
  }
  patch(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.buildBodyRequest("patch", url, data, config)
  }

  private buildBodylessRequest(
    method: Method,
    url: string,
    config: AxiosRequestConfig = {}
  ) {
    return this.request({ ...config, url, method })
  }

  private buildBodyRequest(
    method: Method,
    url: string,
    data: any,
    config: AxiosRequestConfig = {}
  ) {
    return this.request({ ...config, url, method, data })
  }
}
