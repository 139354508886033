import React from "react"
import { Router, Route, Switch } from "react-router"
import { createBrowserHistory } from "history"

import AdminPage from "./pages/AdminPage"
import ProjectPage from "./pages/ProjectPage"
import HomePage from "./pages/HomePage"

const browserHistory = createBrowserHistory()

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="container">
        <Router history={browserHistory}>
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route
              path="/projects/:projectNumber"
              exact
              component={ProjectPage}
            />
            <Route path="/admin" component={AdminPage} />
          </Switch>
        </Router>
      </div>
    </div>
  )
}

export default App
