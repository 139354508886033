import React, { useState, useEffect } from "react"
import { RouteComponentProps } from "react-router"
import MeterForm from "../components/MeterForm"
import useApiClient from "../hooks/useApiClient"
import Meter from "../models/Meter"
import Spinner from "../components/Spinner"
import ContractorAuth from "../models/ContractorAuth"

export interface Props extends RouteComponentProps<{ projectNumber: string }> {}

const ProjectPage: React.FC<Props> = ({ match, location }) => {
  const [meters, setMeters] = useState<Meter[]>()
  const api = useApiClient<{ meters: Meter[] }>()

  const query = new URLSearchParams(location.search.slice(1))
  const contractorAuth: ContractorAuth = {
    id: match.params.projectNumber,
    expires: query.get("expires"),
    hmac: query.get("hmac"),
  }

  useEffect(() => {
    // TODO: get response status stuff from meter form, toss it in here
    api
      .get(`/api/projects/${contractorAuth.id}`, {
        params: {
          expires: contractorAuth.expires,
          hmac: contractorAuth.hmac,
        },
      })
      .then(res => {
        setMeters(res.data.meters)
      })
  }, [api, contractorAuth.id, contractorAuth.expires, contractorAuth.hmac])

  if (meters) {
    return <MeterForm meters={meters} contractorAuth={contractorAuth} />
  } else {
    return <Spinner />
  }
}

export default ProjectPage
